import React from "react";
import Parallax from "react-rellax";

// Components
import { Header } from "../components/Header";
import { Image } from "../components/Image";
import { Section } from "../components/Section";

// CSS
import "../css/scenes/description.scss";

// Constants
const screenWidth = window.screen.width;
const parallaxSpeed = screenWidth >= 1024 && screenWidth <= 1440 ? -1.25 : -2;

export function Description() {
    return (
        <Section backgroundColor="black" isFullScreen layout="flex-end">
            <div className="description__header__container">
                <Parallax speed={parallaxSpeed}>
                    <Header
                        classes="description__header"
                        color="white"
                        isTransparent>
                        <span>WE BUILD</span>
                    </Header>
                </Parallax>
                <Parallax speed={parallaxSpeed}>
                    <Header
                        classes="description__header"
                        color="white"
                        isTransparent>
                        <span>WORLD CLASS</span>
                    </Header>
                </Parallax>
                <Parallax speed={parallaxSpeed}>
                    <Header
                        classes="description__header"
                        color="white"
                        isTransparent>
                        <span>EXPERIENCES</span>
                    </Header>
                </Parallax>
            </div>
            <Image
                classes="description__image"
                src="https://res.cloudinary.com/dxss8zidt/image/upload/v1586270430/billie-compressed_l04amr.gif"
                texture={{
                    src: require("../media/cross-stripes.png"),
                }}
            />
        </Section>
    );
}
