import React from "react";

// CSS
import "../css/components/form.scss";

// Utils
import { emptyFunction } from "../utils/miscUtils";

export function Form(props) {
    const { action, children, method, onSubmit = emptyFunction } = props;

    if (action && method) {
        return (
            <form
                action={action}
                className="form"
                method={method}
                onSubmit={onSubmit}>
                {children}
            </form>
        );
    } else {
        return (
            <form className="form" onSubmit={onSubmit}>
                {children}
            </form>
        );
    }
}

export function Input(props) {
    const {
        onChange = emptyFunction,
        name = "",
        placeholder = "",
        type = "text"
    } = props;

    return (
        <input
            className="input"
            name={name}
            placeholder={placeholder}
            type={type}
            onChange={onChange}
        />
    );
}
