import React from "react";

// Components
import { Header } from "../components/Header";
import { Section } from "../components/Section";
import { Video } from "../components/Video";

// CSS

import "../css/scenes/lets-talk.scss";

// Utils
import { isMobile } from "../utils/mediaQueryUtils";

// Constants
const innerSectionHeight = isMobile() ? "100vw" : "50vw";
const innerSectionStyle = { height: innerSectionHeight };

export function LetsTalk(props) {
    function goToEndOfPage() {
        document.getElementById("end-of-page").scrollIntoView();
    }

    return (
        <Section classes="lets-talk" layout="grid-1-1" padding="none">
            <Section
                backgroundColor="orange"
                layout="flex-center"
                onClick={goToEndOfPage}
                style={innerSectionStyle}>
                <Header
                    classes="lets-talk__header"
                    color="white"
                    isTransparent
                    size="80">
                    <span>LET'S TALK</span>
                </Header>
                <Header
                    classes="lets-talk__header"
                    color="white"
                    isTransparent
                    size="80">
                    <span>LET'S TALK</span>
                </Header>
                <Header
                    classes="lets-talk__header"
                    color="white"
                    isTransparent
                    size="80">
                    <span>LET'S TALK</span>
                </Header>
            </Section>
            <Section layout="flex-center" style={innerSectionStyle}>
                <Video src="https://res.cloudinary.com/dxss8zidt/video/upload/v1586270651/reel-fall-classic-2018-short_dawnup.mp4" />
            </Section>
        </Section>
    );
}
