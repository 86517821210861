import React, { useEffect, useRef, useState } from "react";

// CSS
import "../css/components/image.scss";

// Utils
import { isMobile } from "../utils/mediaQueryUtils";

export function Image(props) {
    const { classes, hasRoundedCorners, src, style, texture } = props;

    const [isVisible, setIsVisible] = useState(false);

    const imageRef = React.useRef();
    const hasRoundedCornersClass = hasRoundedCorners
        ? `image--rounded-corners`
        : "";
    const isVisibleClass = isVisible ? "image--visible" : "";

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => setIsVisible(entry.isIntersecting));
        });
        observer.observe(imageRef.current);
    }, []);

    function renderTexture() {
        return texture.src ? (
            <div
                className="image__texture"
                style={{ backgroundImage: `url(${texture.src})` }}
            />
        ) : (
            ""
        );
    }

    return (
        <div
            className={`image ${hasRoundedCornersClass} ${isVisibleClass} ${classes}`}
            ref={imageRef}
            style={{
                backgroundImage: `url(${src})`,
                ...style,
            }}>
            {renderTexture()}
        </div>
    );
}

export function Slideshow(props) {
    const { children } = props;
    return <div className="slideshow">{children}</div>;
}

export function ScrollBox(props) {
    const { children, id, shouldStartAtBottom } = props;

    const scrollBoxRef = useRef(null);

    // TODO: clear interval when unobserved
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const { offsetHeight, scrollHeight } = entry.target;
                const scrollStart = shouldStartAtBottom ? scrollHeight : 0;
                const scrollEnd = shouldStartAtBottom
                    ? 0
                    : scrollHeight - offsetHeight;
                entry.target.scrollTop = scrollStart;
                if (entry.isIntersecting) {
                    const intervalTime = isMobile() ? 25 : 50;
                    setInterval(() => {
                        const scrollByAmount = shouldStartAtBottom ? -3 : 3;
                        entry.target.scrollBy(0, scrollByAmount);
                        if (entry.target.scrollTop === scrollEnd) {
                            entry.target.scrollTop = scrollStart;
                        }
                    }, intervalTime);
                    observer.unobserve(scrollBoxRef.current);
                }
            });
        });
        observer.observe(scrollBoxRef.current);
    }, [shouldStartAtBottom]);

    return (
        <div className="scroll-box" id={id} ref={scrollBoxRef}>
            {children}
        </div>
    );
}
