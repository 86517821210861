import React from "react";

// CSS
import "../css/components/text.scss";

export function Text(props) {
    const { align, children, color, size, weight } = props;

    const alignClass = align ? `text--align-${align}` : "";
    const colorClass = color ? `text--color-${color}` : "";
    const sizeClass = size ? `text--size-${size}` : "";
    const weightClass = weight ? `text--weight-${weight}` : "";

    return (
        <div
            className={`text ${alignClass} ${colorClass} ${sizeClass} ${weightClass}`}>
            {children}
        </div>
    );
}
