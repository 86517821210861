import React from "react";

// Components
import { Header } from "../components/Header";
import { Marquee } from "../components/Marquee";
import { Section } from "../components/Section";

// CSS
import "../css/scenes/they-do-it-all.scss";

export function TheyDoItAll() {
    return (
        <Section
            backgroundColor="orange"
            classes="they-do-it-all"
            padding="x-none">
            <Marquee>
                <div className="marquee__block marquee__block--first">
                    <Header color="white" isTransparent>
                        <span>
                            THEY DO IT ALL. THEY DO IT ALL. THEY DO IT ALL. THEY
                            DO IT ALL. THEY DO IT ALL.{" "}
                        </span>
                    </Header>
                </div>
                <div className="marquee__block marquee__block--second">
                    <Header color="white" isTransparent>
                        <span>
                            THEY DO IT ALL. THEY DO IT ALL. THEY DO IT ALL. THEY
                            DO IT ALL. THEY DO IT ALL.{" "}
                        </span>
                    </Header>
                </div>
            </Marquee>
        </Section>
    );
}
