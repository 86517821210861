import React from "react";

// CSS
import "../css/components/section.scss";

export function Section(props) {
    const {
        backgroundColor = "white",
        children,
        classes = "",
        id,
        isFullScreen,
        layout,
        onClick,
        padding,
        style,
    } = props;

    const backgroundColorClass = backgroundColor
        ? `section--background-${backgroundColor}`
        : "";
    const isFullScreenClass = isFullScreen ? "section--full-screen" : "";
    const layoutClass = layout ? `section--layout-${layout}` : "";
    const paddingClass = padding ? `section--padding-${padding}` : "";

    return (
        <div
            className={`section ${backgroundColorClass} ${isFullScreenClass} ${layoutClass} ${paddingClass} ${classes}`}
            id={id}
            onClick={onClick}
            style={style}>
            {children}
        </div>
    );
}
