import React, { useEffect, useState, useRef } from "react";

// Components
import { Header } from "../components/Header";
import { Section } from "../components/Section";

// Style
import "../css/scenes/music-first.scss";

export const MusicFirst = props => {
    const [isVisible, setIsVisible] = useState(false);

    const sectionRef = useRef(null);
    const isVisibleClass = isVisible ? "music-first__header--visible" : "";

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    setTimeout(() => {
                        observer.unobserve(sectionRef.current);
                    }, 5000);
                }
            });
        });
        observer.observe(sectionRef.current);
    }, []);

    return (
        <Section
            backgroundColor="orange"
            classes="music-first"
            isFullScreen
            layout="flex-center">
            <Header
                classes={`music-first__header music-first__header--one ${isVisibleClass}`}
                color="white"
                isTransparent>
                <span ref={sectionRef}>A</span>
            </Header>
            <Header
                classes={`music-first__header music-first__header--two ${isVisibleClass}`}
                color="white">
                <span>MUSIC</span>
            </Header>
            <Header
                classes={`music-first__header music-first__header--three ${isVisibleClass}`}
                color="white"
                isTransparent>
                <span>FIRST</span>
            </Header>
            <Header
                classes={`music-first__header music-first__header--four ${isVisibleClass}`}
                color="white">
                <span>CREATIVE</span>
            </Header>
            <Header
                classes={`music-first__header music-first__header--five ${isVisibleClass}`}
                color="white"
                isTransparent>
                <span>STUDIO</span>
            </Header>
        </Section>
    );
};
