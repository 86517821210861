import React, { useEffect, useState } from "react";

// CSS
import "../css/components/video.scss";

export function Video(props) {
    // Available base classes: (1) video, (2) video-background
    const { baseClass = "video", src } = props;

    const [videoRef, setVideoRef] = useState(null);
    const [shouldReveal, setShouldReveal] = useState(false);

    const shouldRevealClass = shouldReveal && `${baseClass}__filter--reveal`;

    useEffect(() => {
        videoRef &&
            videoRef.addEventListener("canplay", () => {
                setShouldReveal(true);
            });
    }, [videoRef]);

    return (
        <div className={baseClass}>
            <div className={`${baseClass}__filter ${shouldRevealClass}`} />
            <video
                autoPlay
                className={`${baseClass}__embed`}
                loop
                muted
                playsInline
                ref={ref => setVideoRef(ref)}
                src={src}
                type="video/mp4"
            />
        </div>
    );
}
