import React from "react";

// CSS
import "./css/app.scss";
import "./css/components/link.scss";

// Scenes
import { Blurb } from "./scenes/Blurb";
import { Contact } from "./scenes/Contact";
import { Description } from "./scenes/Description";
import { Intro } from "./scenes/Intro";
import { IRLComputers } from "./scenes/IRLComputers";
import { LetsTalk } from "./scenes/LetsTalk";
import { MusicFirst } from "./scenes/MusicFirst";
import { TheyDoItAll } from "./scenes/TheyDoItAll";
import { WhatWeDo } from "./scenes/WhatWeDo";
import { WhoWeWorkWith } from "./scenes/WhoWeWorkWith";

function App() {
    return (
        <div className="app">
            <Intro />
            <MusicFirst />
            <Description />
            <TheyDoItAll />
            <WhatWeDo />
            <Blurb />
            <LetsTalk />
            <IRLComputers />
            <WhoWeWorkWith />
            <Contact />
        </div>
    );
}

export default App;
