import React, { useEffect, useState } from "react";

// Components
import { IconGlobe } from "../components/Icons/Icons";
import { List } from "../components/List";
import { Section } from "../components/Section";
import { Square } from "../components/Square";
import { Header } from "../components/Header";

// CSS
import "../css/scenes/what-we-do.scss";

// Utils
import { isMobile } from "../utils/mediaQueryUtils";

// Constants
const overlayTextOptions = {
    digitalDesign: {
        body: (
            <>
                <p>
                    We build beautiful, one-of-a-kind websites tailor-made for
                    your audience that drip with authenticity.
                </p>
                <p>
                    We are obsessed with understanding your personality as an
                    artist or brand, and creating a digital identity that is
                    fresh, relevant, and long-lasting.
                </p>
                <p>
                    From day one we work with you as an extension of your team
                    to understand project scope, timeline, and budget,
                    delivering a final product that tends to feel something like
                    the first time you heard{" "}
                    <a
                        className="what-we-do__square__overlay__link link"
                        href="https://www.youtube.com/watch?v=dX3k_QDnzHE"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Midnight City
                    </a>
                    .
                </p>
            </>
        ),
        header: "Digital Design & Development",
    },
    marketingStrategy: {
        body: (
            <>
                <p>
                    We take a holistic approach to marketing and paid
                    advertising, understanding your vision from the top down and
                    creating goals that are measurable and data-driven.
                </p>
                <p>
                    From conversion rates to social engagement, ROI is the north
                    star that drives strategy. We provide clear, digestible
                    reporting that removes unnecessary industry jargon, and is
                    focused on the metrics that matter most to you.
                </p>
            </>
        ),
        header: "Marketing Strategy",
    },
    eventProduction: {
        body: (
            <>
                <p>
                    Under one roof, we ideate, build and execute events at all
                    scales, ranging from our in-house music festival to national
                    political rallies and leading SXSW showcases.
                </p>
                <p>
                    Our team has worked with everyone from Billie Eilish to
                    Barack Obama. Across all clients, our mission has been
                    constant: create incredible fan-first experiences that are
                    driven by data, new technology, and innovative design.
                </p>
            </>
        ),
        header: "(Virtual) Event Production",
    },
    talentBuying: {
        body: (
            <>
                <p>
                    We collaborate with your team to identify, negotiate, and
                    contract artists for your event. With deep industry
                    relationships, we are able to streamline the booking process
                    for your private event, festival, conference, or show.
                </p>
            </>
        ),
        header: "Talent Buying",
    },
    experientialActivations: {
        body: (
            <>
                <p>
                    We design and build scalable fan-driven activations from the
                    ground up, keeping ROI goals front and center when
                    evaluating logistics and production.
                </p>
            </>
        ),
        header: "Experiential Activations",
    },
    sponsorshipConsulting: {
        body: (
            <>
                <p>
                    We work to understand your goals around sponsorship
                    fundraising, and connect you with relevant brands that align
                    with your vision.
                </p>
                <p>
                    We have negotiated sponsorship deals with leading brands
                    across all verticals ranging from Pepsi to Pandora.
                </p>
            </>
        ),
        header: "Sponsorship Consulting",
    },
    musicCuration: {
        body: (
            <>
                <p>
                    We work to understand your brand and curate the music to
                    compliment it. We have spent the last decade dedicated to
                    music discovery, having worked with artists like The 1975
                    and Kygo before they became household names.
                </p>
            </>
        ),
        header: "Music Curation, Playlisting & Soundtracking",
    },
};

export function WhatWeDo() {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);
    const [lastScrollPosition, setLastScrollPosition] = useState(null);
    const [selectedOverlay, setSelectedOverlay] = useState("eventProduction");

    useEffect(() => {
        if (isMobile()) {
            if (isOverlayVisible) {
                setTimeout(() => {
                    document.body.classList.add("no-scroll");
                    window.scrollTo(0, lastScrollPosition);
                }, 500);
            } else {
                document.body.classList.remove("no-scroll");
                window.scrollTo(0, lastScrollPosition);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOverlayVisible]);

    function selectOverlay(overlayType) {
        setLastScrollPosition(window.pageYOffset);
        setSelectedOverlay(overlayType);
        setIsOverlayVisible(true);
    }

    function toggleOverlayVisibility(bool) {
        setIsOverlayVisible(bool);
    }

    return (
        <Section
            isFullScreen
            classes="what-we-do"
            layout="flex-row-center"
            padding="x-medium"
        >
            <div
                className="what-we-do-header-list"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                    marginRight: "96px",
                }}
            >
                <Header color="orange" isVertical>
                    <span>WHAT</span>
                </Header>
                <Header color="orange" isTransparent isVertical>
                    <span>WE</span>
                </Header>
                <Header color="orange" isVertical>
                    <span>ACTUALLY</span>
                </Header>
                <Header color="orange" isTransparent isVertical>
                    <span>DO</span>
                </Header>
            </div>
            <Square
                borderColor="orange"
                classes="what-we-do__square"
                layout="flex-column-center"
                overlay={{
                    backgroundColor: "orange-light",
                    close: {
                        color: "black",
                    },
                    color: "black",
                    isVisible: isOverlayVisible,
                    text: overlayTextOptions[selectedOverlay],
                    visible: isOverlayVisible,
                }}
                toggleOverlayVisibility={toggleOverlayVisibility}
            >
                <IconGlobe fill="#e92518" />
                <List align="center" styleType="none">
                    <li onClick={() => selectOverlay("digitalDesign")}>
                        Digital Design & Development
                    </li>
                    <li onClick={() => selectOverlay("eventProduction")}>
                        (Virtual) Event Production
                    </li>
                    <li onClick={() => selectOverlay("marketingStrategy")}>
                        Marketing Strategy & Paid Media
                    </li>
                    <li onClick={() => selectOverlay("talentBuying")}>
                        Talent Buying
                    </li>
                    <li
                        onClick={() => selectOverlay("experientialActivations")}
                    >
                        Experiential Activations
                    </li>
                    <li onClick={() => selectOverlay("sponsorshipConsulting")}>
                        Sponsorship Consulting
                    </li>
                    <li onClick={() => selectOverlay("musicCuration")}>
                        Music Curation, Playlisting & Soundtracking
                    </li>
                </List>
            </Square>
        </Section>
    );
}
