import React, { useState } from "react";

// CSS
import "../css/components/square.scss";

export function Square(props) {
    const {
        borderColor,
        children,
        classes = "",
        layout,
        overlay,
        toggleOverlayVisibility,
    } = props;

    const [ref, setRef] = useState({});

    const borderColorClass = borderColor ? `square--border-${borderColor}` : "";
    const layoutClass = layout ? `square--layout-${layout}` : "";

    return (
        <div
            className={`square ${borderColorClass} ${layoutClass} ${classes}`}
            ref={(refArg) => setRef(refArg)}
            style={{ width: ref.offsetWidth }}>
            <SquareOverlay
                overlay={overlay}
                toggleOverlayVisibility={toggleOverlayVisibility}
            />
            {children}
        </div>
    );
}

function SquareOverlay(props) {
    const { overlay, toggleOverlayVisibility } = props;
    const { backgroundColor, close, color, isVisible, text } = overlay;
    const { body, header } = text;

    const backgroundColorClass = backgroundColor
        ? `square__overlay--background-${backgroundColor}`
        : "";
    const colorClass = color ? `square__overlay--color-${color}` : "";
    const visibleClass = isVisible ? "square__overlay--visible" : "";
    const closeColorClass =
        close && close.color
            ? `square__overlay__close--color-${close.color}`
            : "";

    return (
        <div
            className={`square__overlay ${backgroundColorClass} ${colorClass} ${visibleClass}`}>
            <div
                className={`square__overlay__close ${closeColorClass}`}
                onClick={() => toggleOverlayVisibility(false)}>
                X
            </div>
            <span className="square__overlay__text__header">{header}</span>
            <div className="square__overlay__text__body">{body}</div>
        </div>
    );
}
