import React from "react";

// CSS
import "../css/components/button.scss";

// Utils
import { emptyFunction } from "../utils/miscUtils";

export function Button(props) {
    const { isActive, onClick = emptyFunction, text } = props;

    const isActiveClass = isActive ? "button--active" : "";

    return (
        <button className={`button ${isActiveClass}`} onClick={onClick}>
            {text}
        </button>
    );
}
