import React from "react";

// CSS
import "../css/components/header.scss";

export function Header(props) {
    const {
        children,
        classes = "",
        color,
        isTransparent,
        isVertical,
        isVisible,
        margin,
        size,
        style,
    } = props;

    const colorClass = color ? `header--color-${color}` : "";
    const marginClass = margin ? `header--margin-${margin}` : "";
    const sizeClass = size ? `header--size-${size}` : "";
    const isTransparentClass = isTransparent ? "header--transparent" : "";
    const isVerticalClass = isVertical ? "header--vertical" : "";
    const isVisibleClass = isVisible ? "header--visible" : "";

    return (
        <div
            className={`header ${colorClass} ${marginClass} ${sizeClass} ${isTransparentClass} ${isVerticalClass} ${isVisibleClass} ${classes}`}
            style={style}>
            {children}
        </div>
    );
}
