import React, { useEffect, useRef, useState } from "react";

// CSS
import "../css/components/computer.scss";

export function Computer(props) {
    const { children } = props;

    const [isVisible, setIsVisible] = useState(false);

    const computerRef = useRef(null);
    const isVisibleClass = isVisible ? "computer__content__fill--visible" : "";

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        setIsVisible(entry.isIntersecting);
                        setTimeout(() => {
                            observer.unobserve(computerRef.current);
                        }, 2000);
                    }, 1000);
                }
            });
        });
        observer.observe(computerRef.current);
    }, []);

    return (
        <div className="computer" ref={computerRef}>
            <div className="computer__menu-bar">
                <div className="computer__menu-bar__circle" />
                <div className="computer__menu-bar__circle" />
                <div className="computer__menu-bar__circle" />
            </div>
            <div className="computer__content">
                <div className={`computer__content__fill ${isVisibleClass}`} />
                {children}
            </div>
        </div>
    );
}
