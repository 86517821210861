import React from "react";

// Components
import { Computer } from "../components/Computer";
import { Header } from "../components/Header";
import { Section } from "../components/Section";

export function IRLComputers() {
    return (
        <Section
            backgroundColor="black"
            isFullScreen
            layout="flex-center"
            padding="x-tight">
            <Computer>
                <Header color="white" isTransparent>
                    <span>IRL </span>
                </Header>
                <Header color="white" margin="none">
                    <span> + COMPUTERS</span>
                </Header>
            </Computer>
        </Section>
    );
}
