import React, { useEffect, useRef, useState } from "react";

// Components
import { Section } from "../components/Section";
import { Text } from "../components/Text";

// CSS
import "../css/scenes/blurb.scss";

// TODO: Create CSS and/or hooks that handle certain types of animations
export function Blurb(props) {
    const [isVisible, setIsVisible] = useState(false);

    const blurbRef = useRef(null);
    const isVisibleClass = isVisible ? "blurb__text--visible" : "";

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    setTimeout(() => {
                        observer.unobserve(blurbRef.current);
                    }, 5000);
                }
            });
        });
        observer.observe(blurbRef.current);
    }, []);

    return (
        <Section
            backgroundColor="gray-light"
            classes="blurb"
            padding="x-medium">
            <Text color="orange" size="40" weight="400">
                <span
                    className={`blurb__text ${isVisibleClass}`}
                    ref={blurbRef}>
                    Under one roof, we ideate, build and execute concepts at all
                    scales, ranging from our{" "}
                    <a
                        className="link"
                        href="https://allthingsgofallclassic.com"
                        rel="noopener noreferrer"
                        target="_blank">
                        <span>in-house music festival</span>
                    </a>
                    , to leading national political showcases, to your{" "}
                    <a
                        className="link"
                        href="https://foreignair.net"
                        rel="noopener noreferrer"
                        target="_blank">
                        favorite artist's website
                    </a>
                    .
                </span>
            </Text>
        </Section>
    );
}
