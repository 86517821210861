import React from "react";

// Components
import { ScrollBox } from "../components/Image";
import { Header } from "../components/Header";
import { Section } from "../components/Section";

// Utils
import { isMobile } from "../utils/mediaQueryUtils";

// Constants
const innerSectionHeight = isMobile() ? "100vw" : "50vw";
const innerSectionHeightStyle = { height: innerSectionHeight };
const overflowHiddenStyle = { overflow: "hidden" };
const partnersOne = [
    {
        alt: "Billie Eilish logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/billie_i9ldt0.jpg",
    },
    {
        alt: "Heineken logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/heineken_z9rjjz.png",
    },
    {
        alt: "Maggie Rogers logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/maggie_wmf3fx.png",
    },
    {
        alt: "Lyft logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/lyft_hyzga8.png",
    },
    {
        alt: "CHVRCHES logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/chvrches_u7did8.png",
    },
    {
        alt: "Ketel One logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/ketel-one_d8icpd.png",
    },
    {
        alt: "Bulleit logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/bulleit_bn9fsh.png",
    },
];
const partnersTwo = [
    {
        alt: "Carly Rae Jepsen logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/carly-rae-jepsen_zd3cqh.png",
    },
    {
        alt: "Deep Eddy logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/deep-eddy_tzqbns.png",
    },
    {
        alt: "Square logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269485/square_kukfwr.png",
    },
    {
        alt: "Kygo logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/kygo_j8czpu.jpg",
    },
    {
        alt: "Saturday Session logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269485/saturday-session_pqrtmt.jpg",
    },
    {
        alt: "Essentia logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/essentia-water_dyvp2x.png",
    },
    {
        alt: "Mailchimp logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/mailchimp_i5s2dt.png",
    },
    {
        alt: "Pandora logo",
        src:
            "https://res.cloudinary.com/dxss8zidt/image/upload/v1586269484/pandora_dhti4l.png",
    },
];

export function WhoWeWorkWith(props) {
    function renderPartners() {
        const collectionOne = isMobile()
            ? partnersOne.concat(partnersTwo)
            : partnersOne;
        return (
            <Section
                layout="grid-1-1"
                padding="none"
                style={overflowHiddenStyle}>
                <ScrollBox>{renderPartnerLogos(collectionOne)}</ScrollBox>
                {!isMobile() && (
                    <ScrollBox id="scroll-box-2" shouldStartAtBottom>
                        {renderPartnerLogos(partnersTwo)}
                    </ScrollBox>
                )}
            </Section>
        );
    }

    function renderPartnerLogos(partners) {
        return partners.map((partner, i) => (
            <img alt={partner.alt} key={i} src={partner.src} />
        ));
    }

    function renderPartnerPanel() {
        return (
            <Section
                layout="flex-center"
                padding="none"
                style={innerSectionHeightStyle}>
                {renderPartners()}
            </Section>
        );
    }

    function renderTextPanel() {
        return (
            <Section
                backgroundColor="orange"
                layout="flex-center"
                style={innerSectionHeightStyle}>
                <Header color="white" isTransparent size="80">
                    <span>WHO</span>
                </Header>
                <Header color="white" isTransparent size="80">
                    <span>WE'VE</span>
                </Header>
                <Header color="white" isTransparent size="80">
                    <span>WORKED</span>
                </Header>
                <Header color="white" isTransparent size="80">
                    <span>WITH</span>
                </Header>
            </Section>
        );
    }

    const content = isMobile() ? (
        <React.Fragment>
            {renderTextPanel()}
            {renderPartnerPanel()}
        </React.Fragment>
    ) : (
        <React.Fragment>
            {renderPartnerPanel()}
            {renderTextPanel()}
        </React.Fragment>
    );

    return (
        <Section layout="grid-1-1" padding="none">
            {content}
        </Section>
    );
}
