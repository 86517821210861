import React from "react";
import Parallax from "react-rellax";

// Components
import { Header } from "../components/Header";
import { Section } from "../components/Section";
import { Video } from "../components/Video";

export const Intro = () => {
    return (
        <Section backgroundColor="black" isFullScreen layout="flex-center">
            <Video
                baseClass="video-background"
                src="https://res.cloudinary.com/dxss8zidt/video/upload/v1586270651/reel-fall-classic-2018-short_dawnup.mp4"
            />
            <Parallax speed={-4}>
                <Header
                    classes="parallax"
                    color="white"
                    isTransparent
                    style={{ zIndex: 1000 }}>
                    <span>ATG STUDIOS</span>
                </Header>
            </Parallax>
        </Section>
    );
};
