import React from "react";

// CSS
import "../css/components/list.scss";

export function List(props) {
    const { align, children, styleType } = props;

    const alignClass = align && `list--align-${align}`;
    const styleTypeClass = styleType && `list--style-type-${styleType}`;

    return (
        <ul className={`list ${alignClass} ${styleTypeClass}`}>{children}</ul>
    );
}
