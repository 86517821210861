import React, { useState } from "react";

// Components
import { Button } from "../components/Button";
import { Form, Input } from "../components/Form";
import { Header } from "../components/Header";
import { Section } from "../components/Section";

// CSS
import "../css/scenes/contact.scss";

// Constants
const formButtonTexts = {
    complete: "THANKS!",
    default: "SUBMIT",
    disabled: "THANKS!",
};

export function Contact(props) {
    const [buttonState, setButtonState] = useState({
        isActive: false,
        text: "SUBMIT",
    });
    const [form, setForm] = useState({
        name: "",
        email: "",
        company: "",
        project: "",
    });

    function onInputChange(e) {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    }

    function onSubmit(e) {
        e.preventDefault();
        setButtonState({ ...buttonState, isActive: true, text: "SENDING..." });
        const body = JSON.stringify(form);
        const url =
            "https://nqoqjugn04.execute-api.us-east-1.amazonaws.com/dev/email/send";
        fetch(url, {
            body,
            method: "POST",
        })
            .then(onSubmitSuccess)
            .catch(onSubmitError);
    }

    function onSubmitError(err) {
        console.error("form submit error", err);
        setButtonState({ ...buttonState, text: "PLEASE TRY AGAIN" });
    }

    function onSubmitSuccess(res) {
        setButtonState({ ...buttonState, isActive: true, text: "THANK YOU" });
        setTimeout(() => {
            setButtonState({ ...buttonState, isActive: false, text: "SUBMIT" });
        }, 3000);
    }

    const submitCallback = buttonState.isActive ? () => {} : onSubmit;

    return (
        <Section
            backgroundColor="black"
            id="contact"
            isFullScreen
            layout="flex-center"
            padding="x-tight">
            <Header classes="contact__header" color="white">
                <span>CONNECT</span>
            </Header>
            <Form buttonTexts={formButtonTexts} onSubmit={submitCallback}>
                <Input
                    name="name"
                    placeholder="NAME"
                    value={form.name}
                    onChange={onInputChange}
                />
                <Input
                    name="email"
                    placeholder="EMAIL"
                    value={form.email}
                    onChange={onInputChange}
                />
                <Input
                    name="company"
                    placeholder="ARTIST / BRAND / COMPANY"
                    value={form.company}
                    onChange={onInputChange}
                />
                <Input
                    name="project"
                    placeholder="WHAT DO YOU WANT TO BUILD?"
                    value={form.project}
                    onChange={onInputChange}
                />
                <Button
                    isActive={buttonState.isActive}
                    text={buttonState.text}
                />
            </Form>
            <Header classes="contact__footer" color="white">
                <span id="end-of-page">WASHINGTON, DC x BROOKLYN, NY</span>
            </Header>
        </Section>
    );
}
